/* ConnectionsPage.css */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Vollkorn:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');


@keyframes jump {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
}

@keyframes shake {
  0%, 100% { transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% { transform: translateX(-5px); }
  20%, 40%, 60%, 80% { transform: translateX(5px); }
}

.shake-animation {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
}

.jump-animation {
  animation: jump 0.5s ease;
}

.stats-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.averages-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.stats-content {
  background: white;
  color: black;
  padding: 2.5vh;
  border-radius: 1.5vh;
  text-align: center;
  position: relative;
  width: 50vh;
}

.averages-content {
  background: white;
  color: black;
  padding: 2.5vh;
  border-radius: 1.5vh;
  text-align: center;
  position: relative;
  width: 60vh;
}

.stats-section {
  display: flex;
  justify-content: space-between;
  margin-top: 2vh;
}

.stats-item {
  text-align: center;
  flex: 1;
}

.stats-value {
  font-size: 5vh;
  font-weight: bold;
}

.averages-value {
  font-size: 3.5vh;
  font-weight: bold;
}

.stats-label {
  margin-top: 1vh;
  font-size: 2.6vh;
}

.stats-header {
  font-size: 3.5vh;
  font-weight: bold;
  margin-bottom: 2.5vh;
}

.stats-button, .averages-button {
  padding: .8vh 1.7vh;
  font-size: 1em;
  color: white;
  background-color: #0b5b98;
  transform: translateY(-50%);
  border: none;
  border-radius: .5vh;
  cursor: pointer;
  transition: background-color 0.3s;
}

.stats-button {
  right: 1vh;
}

.averages-button {
  left: 1vh;
  padding: .6vh 1.0vh;
}

.stats-button:hover, .averages-button:hover {
  background-color: #0056b3;
}


.footer-links {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4vh;
}

.suggestions-link, .instagram-link, .tiktok-link {
  margin: 0 1vh;
  text-decoration: none;
  color: #000000;
  font-size: 2vh;
  display: flex;
  align-items: center;
}

.instagram-link svg, .tiktok-link svg {
  margin-left: .5vh;
}

.image-attribution {
  text-align: center;
  margin-top: 2vh;
  font-size: 1.5vh;
  color: #888;
}


.suggestions-link:hover, .instagram-link:hover, .tiktok-link:hover {
  text-decoration: underline;
}

body, html {
  margin: 0;
  padding: 0;
  overflow-y: auto;
  font-family: 'Commissioner', sans-serif;
}

.connections-game {
  position: relative;
  max-width: 73.80073800738008vh;
  margin: 0 auto;
  padding: 2.4600246002460024vh;
  text-align: center;
}

h1 {
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 1vh 0;
  margin-top: -3.5vh;
  margin-bottom: 1vh;
  font-size: 3vh;
  /* font-family: 'Commissioner', sans-serif; */
  /* font-family: 'Poppins', sans-serif; */
}

.terms-grid {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1vh;
  justify-content: center;
  margin-bottom: 2vh;
}

.term-block {
  position: relative;
  background: #cccccc;
  border: 1px solid #ddd;
  padding: 1vh; 
  width: 16.6vh; 
  height: 16.6vh; 
  display: flex; 
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  border-radius: 1vh;
  box-sizing: border-box;
}

.term-image {
  width: 110%;
  height: 110%;
  object-fit: cover;
  border-top-left-radius: 1vh;
  border-top-right-radius: 1vh;
}

.text-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10%;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border-bottom-left-radius: 1vh;
  border-bottom-right-radius: 1vh;
  overflow: hidden;
  text-overflow: ellipsis; 
  white-space: nowrap;
  font-weight: 550;
}

.term-block.selected {
  background-color: #858585b6; 
  transform: scale(1.01); 
}

.term-block:hover {
  border-color: #cccccc;
  box-shadow: 0px .5vh 1vh rgba(0, 0, 0, 0.2);
  transform: scale(1.03);
}

.term-block.no-hover:hover {
  border-color: initial;
  box-shadow: none;
  transform: none;
}

.mistakes-section {
  text-align: center;
  font-size: 2.45vh;
  margin-bottom: 1.5vh; 
  /* font-family: 'Poppins', sans-serif; */
}

.game-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -1.5vh;
}

.game-controls button {
  padding: 1.25vh 2.5vh;
  cursor: pointer;
  background-color: #444;
  color: white;
  border: none;
  border-radius: .6vh;
  margin-right: 1.2vh;
  transition: background-color 0.3s;
  height: 5vh; 
  width: 25vh;
  border-radius: 2.5vh;
  box-sizing: border-box;
  font-size: 1.9vh;
}

.game-controls button:hover {
  background-color: #555;
}

.game-controls button:disabled {
  background-color: #444;
  color: #bbb;
  cursor: default;
  opacity: 0.6;
}

.baseball-icon {
  width: 2.6vh;
  height: auto;
  display: inline-block;
  margin-right: .5vh;
}

.popup-message {
  position: absolute;
  top: 4.8%;
  left: 50%;
  transform: translate(-50%, -100%);
  background-color: #f44336;
  color: white;
  padding: 1vh;
  border-radius: .65vh;
  margin-bottom: -3vh;
  z-index: 10; 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(150% - 1.25vh);
  max-width: 43.75vh;
}

.popup-show {
  display: block; 
}

.guessed-group-combined {
  position: relative;
  color: white;
  height: 16.5vh;
  width: 101%;
  padding: 1.25vh;
  margin-bottom: 1.25vh;
  border-radius: .625vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.group-images {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  height: 100%;
  margin-bottom: -2.5vh;
  gap: 3.9vh;
}

.player-image-container {
  position: relative;
  width: 12vh;
  height: calc(100% - 1.25vh);
  margin-bottom: -1.5vh;
}

.guessed-player-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: .625vh;
}

.player-name-overlay {
  position: absolute;
  bottom: 0;
  left: -1.0vh;
  width: 14.0vh;
  padding: .25vh 0;
  background-color: #000;
  color: white;
  text-align: center;
  font-size: 1.2vh;
  font-weight: 550;
  overflow: hidden;
}

.group-description {
  position: absolute;
  width: 100%;
  bottom: 12.925vh;
  text-align: center;
  color: black;
  font-weight: bold;
  font-size: 2.25vh;
  padding: .375vh 0; 
}

.results-popup {
  position: fixed;
  top: 0;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.results-content {
  position: relative;
  background: #fff;
  color: #333;
  padding: 5vh;
  border-radius: 1vh;
  box-shadow: 0 .5vh 1vh rgba(0, 0, 0, 0.1);
  width: auto;
  max-width: 90%;
  min-height: 25vh;
  margin: auto;
  overflow-y: auto;
}

.results-content h2 {
  margin-bottom: 1rem;
}

.close-button {
  position: absolute;
  top: 1.25vh;
  right: 1.25vh;
  color: black;
  background: none;
  border: none;
  font-size: 3vh;
  cursor: pointer;
}

.game-button {
  padding: 1.25vh 2.5vh;
  cursor: pointer;
  background-color: #444;
  color: white;
  border: none;
  border-radius: 2.5vh;
  margin-right: 1.2vh;
  transition: background-color 0.3s;
  height: 5vh;
  width: 25vh;
  box-sizing: border-box;
  font-size: 1.75vh;
}

.copy-summary-button {
  padding: 1.25vh 2.5vh;
  margin-top: 3vh;
  margin-bottom: -1rem;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: .625vh;
  transition: background-color 0.3s;
}

.copy-summary-button:hover {
  background-color: #0056b3;
}

.difficulty-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.difficulty-level {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1.25vh;
}

.difficulty-color {
  width: 2.5vh;
  height: 2.5vh;
  margin-right: 1vh;
}

.difficulty-name {
  font-size: 2vh;
  min-width: 10vh;
  font-weight: 500;
  text-align: left;
}

.game-summary-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2.5vh;
}

.summary-row {
  display: flex;
}

.summary-block {
  width: 4.5vh;
  height: 4.5vh;
  margin: .25vh;
}

.next-puzzle-countdown {
  margin-top: 2.0rem;
  color: #333;
  font-size: 2.5vh;
  font-weight: 500;
}

.message-header {
  font-size: 3.25vh;
  font-weight: 800;
  margin-top: -3vh;
  margin-bottom: 0px;
}

.game-number {
  font-size: 2vh;
  margin-top: -1vh;
  margin-bottom: 2vh;
  font-weight: 500;
}

.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 1;
}

h1 {
  flex-grow: 1;
}



















@media (max-width: 600px) {

  .connections-game {
    position: relative;
    max-width: 100vw;
    margin: 0 auto;
    padding:  5.2356020942408374vw;
    text-align: center;
    overflow-x: auto;
    max-height: 100vh;
    overflow-y: auto;
  }
  
  h1 {
    font-size: 20px;
    margin-top: -20px;
    margin-bottom: 60px;
  }

  .terms-grid {
    position: relative;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    margin-bottom: 45px;
    min-width: 0;
  }
  
  .term-block {
    background: #cccccc;
    border: 1px solid #ddd;
    padding: 10px; 
    height: 100%;
    width: 100%;
    display: flex; 
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    border-radius: 10px;
    box-sizing: border-box;
  }

  .term-block:hover {
    border-color: #cccccc;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transform: scale(1.03);
  }

  .text-overlay {
    font-size: 1.1vh;
  }
  
  .mistakes-section {
    text-align: center;
    font-size: 10px;
    margin-bottom: 30px; 
  }
  
  .game-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2vh;
  }
  
  .game-controls button {
    padding: 5px 5px;
    cursor: pointer;
    background-color: #444;
    color: white;
    border: none;
    border-radius: 5px;
    margin-right: 20px;
    transition: background-color 0.3s;
    height: 30px; 
    width: 130px;
    border-radius: 20px;
    box-sizing: border-box;
    font-size: 12px;
    margin-bottom: 0vh;
  }

  .baseball-icon {
    width: 23px;
    height: auto;
    display: inline-block;
    margin-right: 4px;
  }
  
  .popup-message {
    position: absolute;
    top: 15.0%;
    left: 50%;
    transform: translate(-50%, -100%);
    background-color: #f44336;
    color: white;
    padding: 8px;
    border-radius: 5px;
    margin-bottom: -25px;
    z-index: 10; 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(150% - 10px);
    max-width: 350px;
    font-size: 14px;
  }
  
  .popup-show {
    display: block; 
  }
  
  .guessed-group-combined {
    color: white;
    height: 85px;
    width: 102%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    text-align: center;
  }

  .group-images {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    height: 100%;
    margin-bottom: -2vh;
    gap: 1vh;
  }
  
  .player-image-container {
    position: relative;
    width: 9vh;
    height: 8vh;
    margin-bottom: -2.1vh;
  }
  
  .guessed-player-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
  
  .player-name-overlay {
    position: absolute;
    bottom: 0;
    left: -0.58vh;
    width: 9.85vh;
    padding: 2px 0;
    background-color: #000;
    color: white;
    text-align: center;
    font-size: .7vh;
    font-weight: 550;
    overflow: hidden;
  }
  
  .group-description {
    position: absolute;
    width: 100%;
    bottom: 6.9vh;
    text-align: center;
    color: black;
    font-weight: bold;
    font-size: 1.9vh;
    padding: 3px 0; 
    margin-bottom: 5px;
  }
  
  .results-content h2 {
    margin-bottom: 1rem;
  }
  
  .close-button {
    position: absolute;
    top: 5px;
    right: 25px;
    color: black;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .game-button {
    padding: 10px 20px;
    cursor: pointer;
    background-color: #444;
    color: white;
    border: none;
    border-radius: 20px;
    margin-right: 10px;
    transition: background-color 0.3s;
    height: 40px;
    width: 200px;
    box-sizing: border-box;
    font-size: 14px;
    margin-top: 40px;
  }
  
  .copy-summary-button {
    padding: 10px 20px;
    margin-top: 25px;
    margin-bottom: -1rem;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .copy-summary-button:hover {
    background-color: #0056b3;
  }
  
  .difficulty-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .difficulty-level {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 10px
  }
  
  .difficulty-color {
    width: 1.5vh;
    height: 1.5vh;
    margin-right: 1vh;
  }
  
  .difficulty-name {
    font-size: 1.2vh;
    min-width: 10vh;
    font-weight: 500;
    text-align: left;
  }
  
  .game-summary-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
  }
  
  .summary-row {
    display: flex;
  }
  
  .summary-block {
    width: 40px;
    height: 40px;
    margin: 2px;
  }
  
  .next-puzzle-countdown {
    margin-top: 2.0rem;
    color: #333;
    font-size: 19px;
    font-weight: 500;
  }
  
  .message-header {
    font-size: 26px;
    font-weight: 800;
    margin-top: -30px;
    margin-bottom: 0px;
  }
  
  .game-number {
    font-size: 20px;
    margin-top: -15px;
    margin-bottom: 15px;
    font-weight: 500;
  }
  
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
}





@media (min-height: 800px) and (max-width: 600px) {
  h1 {
    margin-bottom: 8.4vw;
    margin-top: 2vw;
  }
  .game-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 10px;
    margin-top: -.9vh;
  }
  .game-button {
    margin-top: 1vh;
  }
  .group-description {
    bottom: 7.625vh;
    font-size: 1.5vh;
  }
  .text-overlay {
    width: 100%;
    height: 10%;
    font-size: .8vh;
  }
}



@media (min-height: 400px) and (min-height: 800px) and (max-width: 600px) {
  .terms-grid {
    gap: 1vh;
    width: 100%;
    margin-bottom: 7.5vh;
  }
  .term-block {
    aspect-ratio: 1 / 1;
    height: 101%;
    width: 101%;
  }
  .guessed-group-combined {
    color: white;
    height: 11.30vh;
    right: 1.7vh;
    width: 109.5%;
    padding: 1vh;
    margin-bottom: 1vh;
    border-radius: .4vh;
  }
  .mistakes-section {
    font-size: 1.95vh;
  }
  .baseball-icon {
    width: 2.4vh;
  }
  .group-images {
    height: 100%;
    gap: 1.5vh;
  }
  .player-image-container {
    width: 9vh;
    height: 100%;
    bottom: 1.1vh;
    margin-bottom: -2.1vh;
  }
  .guessed-player-image {
    width: 100%;
    height: 100%;
  } 
  .player-name-overlay {
    bottom: 0vh;
    left: -1.08vh;
    width: 10.65vh;
    font-size: .9vh;
  }
}



@media (min-height: 900px) and (max-width: 600px) {
  h1 {
    margin-bottom: 9.8vw;
    margin-top: 2vw;
  }
  .game-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
  }
  .game-button {
    margin-top: -2vh;
  }
  .mistakes-section {
    font-size: 1.95vh;
  }
  .baseball-icon {
    width: 2.3vh;
  }
  .group-description {
    bottom: 7.325vh;
    font-size: 1.5vh;
  }
  .terms-grid {
    gap: 1vh;
    margin-bottom: 5.4vh;
    width: 100%;
  }
  .term-block {
    aspect-ratio: 1 / 1;
    height: 100%;
    width: 100%;
  }
  .guessed-group-combined {
    color: white;
    height: 10.70vh;
    right: 2.45vh;
    width: 114.5%;
    padding: 1vh;
    margin-bottom: 1vh;
    border-radius: .4vh;
  }
  .group-images {
    height: 100%;
    gap: 1vh;
  }
  .player-image-container {
    width: 9.2vh;
    height: 8.4vh;
    bottom: 1.1vh;
    margin-bottom: -2.1vh;
  }
  .guessed-player-image {
    width: 100%;
    height: 100%;
  } 
  .player-name-overlay {
    bottom: 0vh;
    left: -0.58vh;
    width: 9.85vh;
    font-size: .7vh;
  }
}




@media (max-height: 500px) {
  .mistakes-section {
    margin-top: 8vh;
  }
}






@media (max-height: 800px) and (min-width: 601px) {
  .connections-game {
    width: 100vw;
    position: relative;
    margin: 0 auto;
    padding: 2.5380710659898478vh;
    text-align: center;
  }
  
  h1 {
    font-size: 3.045685279187817vh;
    margin-top: -4.5380710659898478vh;
    width: 100%;
    margin-bottom: 1.8380710659898478vh;
  }
  
  .terms-grid {
    gap: 1vh;
    margin-bottom: 2.469vh;
  }
  
  .term-block {
    aspect-ratio: 1 / 1;
    height: 16vh;
    width: 16vh;
  }

  .text-overlay {
    font-size: 1.65vh;
  }
  
  .term-block.selected {
    background-color: #858585b6; 
    transform: scale(1.01); 
  }
  
  .term-block:hover {
    border-color: #cccccc;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transform: scale(1.03);
  }
  
  .mistakes-section {
    text-align: center;
    font-size: 2.15vh;
    margin-bottom: 1.9vh; 
  }
  
  .game-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .game-controls button {
    padding: 10px 20px;
    cursor: pointer;
    background-color: #444;
    color: white;
    border: none;
    border-radius: 5px;
    margin-right: 10px;
    transition: background-color 0.3s;
    height: 5vh; 
    width: 25.380710659898478vh;
    border-radius: 2.5380710659898478vh;;
    box-sizing: border-box;
    font-size: 1.5380710659898478vh;
  }
  
  .game-controls button:hover {
    background-color: #555;
  }
  
  .game-controls button:disabled {
    background-color: #444;
    color: #bbb;
    cursor: not-allowed;
    opacity: 0.6;
  }
  
  .baseball-icon {
    width: 3.5vh;
    height: auto;
    display: inline-block;
    margin-right: 4px;
  }
  
  .popup-message {
    position: absolute;
    top: 4.8%;
    left: 50%;
    transform: translate(-50%, -100%);
    background-color: #f44336;
    color: white;
    padding: 8px;
    border-radius: 5px;
    margin-bottom: -3vh;
    z-index: 10; 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(150% - .8vh);
    max-width: 350px;
    height: 5vh;
    font-size: 2vh
  }
  
  .popup-show {
    display: block; 
  }
  
  .guessed-group-combined {
    color: white;
    height: 16vh;
    width: 100%;
    padding: 2.1413276231263385vh;
    margin-bottom: 1.1413276231263385vh;
    border-radius: 1vh;
    text-align: center;
  }
  
  .group-images {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    height: 100%;
    margin-bottom: -2vh;
    gap: 1.5vh;
  }
  
  .player-image-container {
    position: relative;
    width: 13.61vh;
    height: calc(100% - .01vh);
    margin-bottom: -2.2vh;
  }
  
  .guessed-player-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
  
  .player-name-overlay {
    position: absolute;
    bottom: 0;
    left: -.7vh;
    width: 14.92vh;
    padding: 2px 0;
    background-color: #000;
    color: white;
    text-align: center;
    font-size: 1.1vh;
    font-weight: 550;
    overflow: hidden;
  }
  
  .group-description {
    position: absolute;
    width: 100%;
    bottom: 11.7vh;
    text-align: center;
    color: black;
    font-weight: bold;
    font-size: 2.45vh;
    padding: 3px 0; 
  }

  .results-content h2 {
    margin-bottom: 1rem;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    color: black;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .game-button {
    padding: 1vh 2vh;
    cursor: pointer;
    background-color: #444;
    color: white;
    border: none;
    border-radius: 1.5vh;
    margin-right: 1vh;
    transition: background-color 0.3s;
    height: 5.738880918220947vh;
    width: 28.694404591104735vh;
    box-sizing: border-box;
    font-size: 1.7vh;
  }
  
  .copy-summary-button {
    padding: 1vh 2vh;
    margin-top: 2vh;
    margin-bottom: -1rem;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: .3vh;
    transition: background-color 0.3s;
    font-size: 2vh;
  }
  
  .copy-summary-button:hover {
    background-color: #0056b3;
  }
  
  .difficulty-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .difficulty-level {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1vh;
  }
  
  .difficulty-color {
    width: 1.9vh;
    height: 1.9vh;
    margin-right: 8px;
  }
  
  .difficulty-name {
    font-size: 1.5vh;
    min-width: 10.152284263959391vh;
    font-weight: 500;
    text-align: left;
  }
  
  .game-summary-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2vh;
  }
  
  .summary-row {
    display: flex;
  }
  
  .summary-block {
    width: 4.441624365482234vh;
    height: 4.441624365482234vh;
    margin: 2px;
  }
  
  .next-puzzle-countdown {
    margin-top: 2.0rem;
    color: #333;
    font-size: 2.4vh;
    font-weight: 500;
  }
  
  .message-header {
    font-size: 3.3vh;
    font-weight: 800;
    margin-top: -4vh;
    margin-bottom: 0px;
  }
  
  .game-number {
    font-size: 2vh;
    margin-top: -1.9vh;
    margin-bottom: 1.9vh;
    font-weight: 500;
  }
  
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
}


















@media (max-height: 500px) and (min-width: 601px) {
  body, html {
    overflow-y: auto;
  }
  
  .connections-game {
    width: 70vh;
    padding: 2.5380710659898478vh;
  }
  
  h1 {
    font-size: 2.845685279187817vh;
    margin-top: -2.5380710659898478vh;
    width: 100%;
    margin-bottom: 2.5380710659898478vh;
  }
  
  .terms-grid {
    gap: 1vh;
    margin-bottom: 1.2vh;
  }
  
  .term-block {
    height: 100%;
    width: 100%;
  }
  
  .group-description {
    bottom: 10.7vh;
    font-size: 2.45vh;
  }

  .mistakes-section {
    text-align: center;
    font-size: 2.15vh;
    margin-bottom: 1.2vh; 
  }
  
  .guessed-group-combined {
    height: 15vh;
  }
}




















@media (max-height: 800px) and (max-width: 600px) {
  
  .connections-game {
    position: relative;
    text-align: center;
    width: 100vw;
    max-width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    max-height: 100vh;
    overflow-y: auto;
  }
  
  h1 {
    font-size: 2.445685279187817vh;
    margin-top: -3.95380710659898478vh;
    width: 100%;
    margin-bottom: 1.5380710659898478vh;
  }
  
  .terms-grid {
    gap: 1vh;
    margin-bottom: 6.6vh;
    width: 100%;
  }
  
  .term-block {
    aspect-ratio: 1 / 1;
    height: 100%;
    width: 100%;
  }
  
  .term-block.selected {
    background-color: #858585b6; 
    transform: scale(1.01); 
  }
  
  .mistakes-section {
    font-size: 2.15vh;
    margin-bottom: 2.6vh; 
  }

  .game-controls button {
    padding: 10px 20px;
    border-radius: 5px;
    margin-right: 10px;
    height: 5vh; 
    width: 25.380710659898478vh;
    border-radius: 2.5380710659898478vh;;
    font-size: 1.0780710659898478vh;
  }
  
  .baseball-icon {
    width: 3.5vh;
    margin-right: 4px;
  }
  
  .popup-message {
    top: 4.8%;
    left: 50%;
    transform: translate(-50%, -100%);
    padding: 8px;
    border-radius: 5px;
    margin-bottom: -3vh;
    width: calc(150% - .8vh);
    max-width: 350px;
    height: 5vh;
    font-size: 2vh
  }
  
  .guessed-group-combined {
    height: 13.69vh;
    width: 106.5%;
    left: -1.5vh;
    padding: 2.1413276231263385vh;
    margin-bottom: 1.1413276231263385vh;
    border-radius: 1vh;
  }

  .group-description {
    bottom: 9.9vh;
    font-size: 1.9vh;
    padding: 3px 0; 
    margin-bottom: .3vh;
  }

  .group-images {
    gap: 2.1vh;
  }
  
  .player-image-container {
    left: 1.5vh;
    width: 10.2vh;
    height: 100%;
    margin-bottom: -1.5vh;
  }
  
  .guessed-player-image {
    width: 80%;
    height: 105%;
    object-fit: cover;
    border-radius: .625vh;
  }
  
  .player-name-overlay {
    bottom: -.8vh;
    left: -3.0vh;
    width: 14.0vh;
    padding: .25vh 0;
    font-size: 1.0vh;
    font-weight: 550;
  }
  
  .game-button {
    padding: 1vh 2vh;
    border-radius: 1.5vh;
    margin-right: 1vh;
    height: 5.738880918220947vh;
    width: 28.694404591104735vh;
    font-size: 1.7vh;
  }
  
  .copy-summary-button {
    padding: 1vh 2vh;
    margin-top: 2vh;
    margin-bottom: -1rem;
    border-radius: .3vh;
    font-size: 2vh;
  }
  
  .difficulty-level {
    margin-bottom: 1vh;
  }
  
  .difficulty-color {
    width: 1.9vh;
    height: 1.9vh;
  }
  
  .difficulty-name {
    font-size: 1.5vh;
    min-width: 10.152284263959391vh;
  }
  
  .game-summary-grid {
    margin: 2vh;
  }
  
  .summary-block {
    width: 4.441624365482234vh;
    height: 4.441624365482234vh;
    margin: 2px;
  }
  
  .next-puzzle-countdown {
    margin-top: 2.0rem;
    font-size: 2.4vh;
  }
  
  .message-header {
    font-size: 3.3vh;
    margin-top: -4vh;
    margin-bottom: 0px;
  }
  
  .game-number {
    font-size: 2vh;
    margin-top: -1.9vh;
    margin-bottom: 1.9vh;
  }
}





@media (max-height: 800px) and (max-width: 600px) {
  .connections-game {
    position: relative;
    text-align: center;
    /* width: 100vw;
    max-width: 100%; */
    margin: 0 auto;
    box-sizing: border-box;
    overflow-x: auto;
  }
}


@media (min-height: 599px) and (min-width: 1000px) {
  html, body {
    height: 100%;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }  
}



@media (max-width: 430px) {
  .guessed-group-combined {
    width: 100%;
    left: 0vh;
  }
  .mistakes-section {
    margin-top: -1vh; 
  }
}